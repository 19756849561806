import React, { useRef } from 'react';
import Base32Encode from './Base32Encode';
import ExampleText from '../../../components/ExampleText';
import settings from '../../../settings';

export default function({children,secretBase64,setSecretBase32}){
    const refs	=	{
		secret	:	useRef(null),
		wrapper	:	useRef(null),
	};

	const styles=	{
		pre	:	{
			color	:	'Gold',
			margin	:	0,
		},
	};

	const formSubmit = function(event){

		const form	=	event.target;
		const input	=	form.secret_base64;

		const string	=	input.value;

		event.preventDefault();
		event.stopPropagation();

		//	qaGUrucieszYKA==
		const buffer			=	Buffer.from(string,'base64');
		const secret_encoded	=	Base32Encode(buffer);

		setSecretBase32(secret_encoded);	//	Pass State to parent

		refs.secret.current.innerHTML		=	secret_encoded;
		refs.wrapper.current.style.display	=	'block';

		return false;
	};
    return (
        <div>
            <form onSubmit={formSubmit}>
                <div className="form-group">
                    <input name="secret_base64" type="text" className="form-control" placeholder="The Shared Secret buffer encoded as base64" defaultValue={secretBase64}/>
					<ExampleText>{settings.example_values.shared_secret_as_base64}</ExampleText>
                </div>
                <button type="submit" className="btn btn-primary">Get Shared Secret</button>
            </form>
            <div ref={refs.wrapper} Style="display:none;">
				<hr />
                <table className="table table-dark">
                    <caption>Shared Secret</caption>
                    <tbody>
						<tr>
                        	<th>Shared Secret</th>
							<td><pre style={styles.pre} ref={refs.secret}></pre></td>
						</tr>
                    </tbody>
                </table>
            </div>
        </div>
    );

};